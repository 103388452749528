<template>
  <b-row class="mt-0 mt-lg-3">
    <b-col xl="3">
      <card :card="mod" :link="false" :seeMore="true" />
      <archetype
        class="mt-6"
        :archetype="archetype"
        :svg="archetypeSvg"
        :archetypeId="archetypeId"
        :details="false"
        v-if="isProfileLoaded"
      />
    </b-col>
    <b-col xl="9">
      <b-row>
        <b-col cols="12">
          <b-card
            class="card-custom card-stretch gutter-b"
            body-class="p-0 d-flex flex-column"
            header-class="border-0 pt-5"
          >
            <template #header>
              <b-card-title class="font-weight-bolder">
                <div class="card-label">
                  {{ $t("profile.archetype.help.title") }}
                </div>
              </b-card-title>
            </template>
            <div class="card-body text-left">
              <p
                v-for="(value, key) in $t('profile.archetype.help.description')"
                :key="key"
                v-html="value"
              />
            </div>
          </b-card>
        </b-col>
        <b-col xl="12">
          <profile-tabs
            :archetypeSvg="archetypeSvg"
            :archetypeId="archetypeId"
            :tabs="archetypeTabs"
            :title="archetype.name.text"
            :object="archetype"
            :info="false"
            v-if="isProfileLoaded && Object.keys(archetype).length > 0"
          />
          <empty-card type="profile.archetype" v-else />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

import Card from "@/components/profile/Card.component";
import ProfileTabs from "@/components/profile/Tabs.component";
import Archetype from "@/components/profile/Archetype.component";
import EmptyCard from "@/components/EmptyCard.component.vue";

export default {
  name: "Wellbeing",
  components: {
    Card,
    ProfileTabs,
    Archetype,
    EmptyCard
  },
  methods: {
    ...mapActions("Profile", ["loadProfile"])
  },
  data() {
    return {
      object: {}
    };
  },
  computed: {
    ...mapGetters("Profile", ["isProfileLoaded", "modules", "archetype"]),
    ...mapGetters("User", ["archetypeSvg", "archetypeId"]),
    ...mapGetters("Constants", ["isConstantsActive", "getConstant"]),
    mod() {
      return _.find(this.modules, e => {
        return e.id === "archetype";
      });
    },
    archetypeTabs() {
      return [
        {
          key: "description",
          title: this.$t("profile.tabs.description"),
          icon: "flaticon2-chat-1"
        },
        {
          key: "motivation",
          title: this.$t("profile.tabs.motivation"),
          icon: "flaticon2-drop"
        },
        {
          key: "work",
          title: this.$t("profile.tabs.work"),
          icon: "flaticon2-shield"
        },
        {
          key: "strengths",
          title: this.$t("profile.tabs.strengths"),
          icon: "fa fa-dumbbell"
        },
        {
          key: "challenges",
          title: this.$t("profile.tabs.challenges"),
          icon: "fa fa-mountain"
        }
      ];
    }
  },
  mounted() {
    if (this.isConstantsActive && !this.isProfileLoaded) this.loadProfile();
  },
  watch: {
    isConstantsActive(value) {
      if (value === true) {
        this.loadProfile();
      }
    }
  }
};
</script>
